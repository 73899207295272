<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Launch Pad</h1>
                    <div class="user_info">
                        <img :src="contact.photo" alt="">
                        <div>
                            <h5 v-if="contact.name">{{ contact.name }}</h5>
                            <h6 v-if="contact.email">{{ contact.email }}</h6>
                        </div>
                    </div>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                <ul class="tab_sec">
                    <li @click="tab = 1" :class="{ active: tab === 1 }">Actions</li>
                    <li @click="tab = 2" :class="{ active: tab === 2 }">Settings</li>
                    <li @click="tab = 3" :class="{ active: tab === 3 }">Advanced</li>
                </ul>
            </div>
            <div class="modal_body">
                <div class="tab_content">
                    <keep-alive v-if="tab == 1">
                        <action :contact="contact" />
                    </keep-alive>
                    <keep-alive v-if="tab == 2">
                        <setting  :contact="contact" />
                    </keep-alive>
                    <keep-alive v-if="tab == 3">
                        <advance  :contact="contact" :tab-watcher="tab" :close-launch-pad="closeModal" />
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    const Action = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Action'))
    const Advance = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Advance'))
    const Setting = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Setting'))

    export default {
        name: 'Launch Pad',

        data () {
            return  {
                tab: 1,
            };
        },

        props: {
            modelValue: Boolean,
            contact: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.tab = 1;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            Action,
            Advance,
            Setting,
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    };
</script>

<style scoped>
    .modal_body .tab_content{
        height: 100%;
    }
    .modal_body .tab_content :deep(.setting_wpr){
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal_body .tab_content :deep(.setting_wpr .section_wpr:last-of-type){
        margin-top: auto;
        padding-bottom: 50px;
    }

    .modal_container {
        height: 100% !important;
    }
</style>
